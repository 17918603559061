<template>
  <div>
    <v-alert
      v-if="successNotificationShow"
      dense
      text
      dismissible
      type="success"
    >
      {{ $t('gamification.successMessage') }}
    </v-alert>

    <ib-single-accordion
      v-if="!isAwardActivated"
      :value="getGettingStartedExpanded"
      :title="`${$t('gettingStarted')}`"
      @input="setGettingStartedExpandedState($event)"
    >
      <ib-card
        class="gamification-card"
        :animation="false"
      >
        <p class="gamification-title font-outfit-regular">
          {{ $t('gamification.title') }}
        </p>
        <v-row>
          <v-col
            :cols="12"
            :md="6"
            :lg="4"
            class="d-flex justify-center column-padding"
          >
            <gamification-award />
          </v-col>

          <v-col
            :cols="12"
            :md="6"
            :lg="4"
            class="middle-column"
          >
            <gamification-items-list />
          </v-col>

          <v-col
            :cols="12"
            :md="12"
            :lg="4"
            class="d-flex column-padding"
          >
            <gamification-progress />
          </v-col>
        </v-row>
      </ib-card>
    </ib-single-accordion>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import GamificationAward from '@/views/Home/Gamification/Components/GamificationAward.vue'
import GamificationItemsList from '@/views/Home/Gamification/Components/GamificationItemsList.vue'
import GamificationProgress from '@/views/Home/Gamification/Components/GamificationProgress.vue'

export default {
  name: 'Gamification',

  components: {
    GamificationProgress,
    GamificationItemsList,
    GamificationAward
  },

  data () {
    return {
      accordion: true,
      successNotificationShow: false
    }
  },

  computed: {
    ...mapGetters('user/gamification', ['getPercentage', 'isAwardActivated']),
    ...mapGetters('theme', ['getGettingStartedExpanded'])
  },

  watch: {
    isAwardActivated (value) {
      if (value) {
        this.successNotificationShow = true
      }
    }
  },

  mounted () {
    this.fetchGamification()
      .then(() => {
        this.$intercom.update({
          GamificationProgress: this.getPercentage
        })
      })
  },

  methods: {
    ...mapActions('user/gamification', ['fetchGamification']),
    ...mapMutations('theme', ['setGettingStartedExpandedState'])
  }
}
</script>

<style scoped lang="scss">
.gamification-card {
  padding: 23px 30px 35px 30px;

  .column-padding {
    padding: 12px 30px;
  }

  @include media-breakpoint-up($md) {
    padding: 24px 30px 34px 30px;
  }

  .gamification-title {
    margin: 0 0 38px 0;
    font-size: 18px;

    @include media-breakpoint-up($md) {
      font-size: 20px;
    }
  }

  .middle-column {
    margin: 12px 0;

    @include media-breakpoint-up($md) {
      margin: 0;
      padding: 12px 50px;
    }
  }
}
</style>
