import { render, staticRenderFns } from "./Gamification.vue?vue&type=template&id=22a0a084&scoped=true"
import script from "./Gamification.vue?vue&type=script&lang=js"
export * from "./Gamification.vue?vue&type=script&lang=js"
import style0 from "./Gamification.vue?vue&type=style&index=0&id=22a0a084&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a0a084",
  null
  
)

export default component.exports