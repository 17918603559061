<template>
  <div>
    <p class="users-home-title mt-0">
      {{ $t('settings.users') }}
    </p>
    <v-row>
      <v-col :cols="12" :md="6">
        <add-new-card
          :title="`${$t('inviteCollaborator')}`"
          horizontal
          min-height="84px"
          :disabled="!isRoleOwner"
          @click="openInviteDialog"
        />
      </v-col>
      <template v-if="collaborators.length">
        <v-col :cols="12" :md="6">
          <user-card
            :email="lastCollaborator.email"
            :user="lastCollaborator.user"
            :ideas="lastCollaborator.ideas"
            :role="lastCollaborator.role"
          />
        </v-col>
      </template>
    </v-row>

    <div class="see-all-users font-outfit-regular">
      <router-link :to="{name: 'users'}" class="d-flex align-items-center">
        {{ $t('viewAllUsers') }}
        <div class="icon-wrapper">
          <i class="icon ib-icon ib-icon-arrow-right-2" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import AddNewCard from '@/components/_v2/AddNewCard.vue'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import UserCard from '@/views/Home/InvitedUsers/Components/UserCard.vue'
import UserRoleEnum from '@/constants/UserRoleEnum'
import _last from 'lodash/last'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Users',

  components: { UserCard, AddNewCard },

  mixins: [MixinIdeaRoles],

  computed: {
    ...mapGetters('user/collaborators', [
      'collaborators',
      'countCollaborators'
    ]),

    ...mapGetters('user', [
      'isSubscribed',
      'isFreeTrial',
      'isUsersLimitExceeded'
    ]),

    lastCollaborator () {
      return _last(this.collaborators)
    }
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState',
      'setDialogUserInviteState'
    ]),

    openInviteDialog () {
      if (!this.isSubscribed || this.isUsersLimitExceeded) {
        this.setDialogUpgradeState(true)

        return
      }

      this.setDialogUserInviteState({
        role: UserRoleEnum.BUDDY,
        visible: true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.users-home-title {
  font-size: 24px;
  margin-bottom: 28px;
}

.see-all-users {
    margin-top: 15px;

  a {
    color: #0E64E6 !important;
    text-decoration: none;
    font-size: 16px;

    .icon-wrapper {
      position: relative;
      height: 20px;

      .icon {
        transition: all 0.25s ease-in-out !important;
        font-size: 16px;
        position: absolute;
        top: 4px;
        left: 2px;
      }
    }

    &:hover {
      .icon {
        margin-left: 5px;
      }
    }
  }
}
</style>
